import { UsersTypes, UserState, UserActionTypes } from "./types";
import { Reducer } from "redux";

const initialState: UserState = {
  inPromise: false,
  checkAuthFail: false,
  LoginLoading: false,
  tipo: "",
  userLoad: false,
  userProfile: {
    tipo: ""
  },
  edificios: [],
  edificiosLoad: false,
  message: "",
  errorMessage: "",
  _id: "",
  isLogged: null,
  checkingAuth: false,
  checkLogged: false,
  newContentAvailable: false,
};

export const userReducer: Reducer<UserState> = (
  state = initialState,
  action : UserActionTypes
) => {
  switch (action.type) {
    case UsersTypes.NEW_CONTENT_AVAILABLE:
      return {
        ...state,
        newContentAvailable: true,
      };
    case UsersTypes.RESET_NEW_CONTENT_AVAILABLE:
      return {
        ...state,
        newContentAvailable: false,
      };
    case UsersTypes.LOGIN_REQUEST:
      return { ...state, inPromise: true, LoginLoading: true, message: "", errorMessage: "" };
    case UsersTypes.LOGIN_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        tipo: action.payload.user.tipo,
        isLogged: true,
        checkLogged: true,
        checkingAuth: false,
        checkAuthFail: false,
        _id: action.payload.user._id,
        inPromise: false,
        LoginLoading: false,
        errorMessage: "",
        userProfile: { ...state.userProfile },
      };
    case UsersTypes.CHECK_AUTH_STATUS:
      return {
        ...state,
        inPromise: true,
        checkingAuth: true,
      };
    case UsersTypes.CHECK_AUTH_FAIL:
      return {
        ...state,
        inPromise: false,
        checkingAuth: false,
        checkAuthFail: true,
        isLogged: false
      };
    case UsersTypes.CHECK_AUTH_LOADING:
      return {
        ...state,
        checkingAuth: true,
      };
    case UsersTypes.LOGIN_FAIL:
      return {
        ...state,
        errorMessage: action.payload.message,
        isLogged: false,
        LoginLoading: false,
        inPromise: false,
      };

    case UsersTypes.GET_USER_REQUEST:
      return { ...state, userLoad: false, inPromise: true };
    case UsersTypes.GET_USER_SUCCESS:
      return {
        ...state,
        userProfile: { ...action.payload.userProfile },
        userLoad: true,
        inPromise: false,
      };
    case UsersTypes.GET_USER_PROFILE_REQUEST:
      return { ...state, inPromise: true };
    case UsersTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: {...action.payload.userProfile },
        userLoad: true,
        inPromise: false,
      };
    case UsersTypes.GET_USER_EDIFICIOS:
      return {
        ...state,
        inPromise: true,
        edificiosLoad: false,
        message: "",
        errorMessage: ""
      };
    case UsersTypes.SET_USER_EDIFICIOS:
      return {
        ...state,
        edificios: action.payload.edificios,
        inPromise: false,
        isLoad: true
      };
    case UsersTypes.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      };
    case UsersTypes.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
