import React, { useState } from "react";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonModal,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle
} from "@ionic/react";
import { close } from "ionicons/icons";
import "./GaleriaFotos.css";
import "../General.css"; // Asegúrate de tener los estilos personalizados
import { ImagenObra } from "../../store/ducks/observaciones/types";



type GaleriaFotosProps = {
  imagenes: ImagenObra[];
};

const GaleriaFotos: React.FC<GaleriaFotosProps> = ({ imagenes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  // Función para abrir el modal y mostrar la imagen seleccionada
  const openImage = (imgUrl: string) => {
    setSelectedImage(imgUrl);
    setIsOpen(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <IonGrid style={{ padding: 0, margin: 0 }}>
        <IonRow style={{ padding: 0, margin: 0 }}>
          {imagenes.map((imagen) => (
            <IonCol key={imagen._id} size="6" size-md="4" size-lg="3">
              <div style={{ overflow: "hidden", borderRadius: "15px" }}>
                <IonImg
                  src={imagen.imgUrl}
                  onClick={() => openImage(imagen.imgUrl)}
                  className="img-galeria-observacion"
                />
              </div>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)} style={{ height: "100vh" }}>
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setIsOpen(false)}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
            <IonTitle>Imagen</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div
          style={{
            display: "flex",
            justifyContent: "center",

            height: "100%", // Asegura que el contenedor ocupe toda la altura disponible
          }}
        >
          {selectedImage && (
           
              <IonImg
                src={selectedImage}
                style={{
                  maxHeight: "100%", // Asegura que la imagen ocupe toda la altura
                  maxWidth: "100%",  // Asegura que la imagen no se estire más allá del contenedor
                  objectFit: "contain", // Ajusta la imagen para que se vea completa sin recortes
                }}
              />
         
          )}
        </div>
      </IonModal>
    </div>
  );
};

export default GaleriaFotos;
