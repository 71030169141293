import React from "react";
import {
    IonCard,
    IonCardContent
} from "@ionic/react";
import "./ObraListItem.css";
import "../../General.css";
import { FiCalendar } from "react-icons/fi";

import moment from "moment";
import "moment/locale/es";
import { IObra } from "../../../store/ducks/obras/types";

type ObraListItemProps = {
    link: string;
    src: string;
    alt: string;
    obra: IObra;
};

const ObraListItem: React.FC<ObraListItemProps> = ({
    link,
    src,
    alt,
    obra

}) => {

    return (
        <IonCard
            routerLink={link}
            routerDirection="root"
            className="ion-card-list"
        >
            <div className="img-card-box">
                <img alt={alt} src={src} className="img-card-list" />
            </div>
            <IonCardContent>
                <div className="mb-2 d-flex ion-align-items-center">
                    <span className="font-13">
                        <FiCalendar className="mr-1" />
                        {moment(obra?.createdAt).locale("es").format("LL")}
                    </span>
                </div>
                <h4 className="mb-2 font-16 font-dark-grey font-weight-500 txt-titulo-list-item">
                    {`${obra?.titulo}`}
                </h4>
                <div className="mb-1 justify-content-between d-flex">
                    <span className="text-capitalize font-14">
                        {`Estado`}
                    </span>
                    <span className="text-capitalize font-14">
                        {`${obra?.estado}`}
                    </span>
                </div>
                <div className="mb-1 justify-content-between d-flex">
                    <span className="font-14">
                        {`Fecha de inicio`}
                    </span>
                    <span className="font-14">
                        {moment(obra?.fechaInicio).locale("es").format("LL")}
                    </span>
                </div>
            </IonCardContent>
        </IonCard>
    );
}
export default React.memo(ObraListItem);