import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEVELOPMENT : process.env.REACT_APP_BASE_URL_PRODUCTION;

const userLogInstance = axios.create({
  baseURL: baseURL
});

/* export const requestGetUserById = (idUser: string, token: string) =>
  userLogInstance.request({
    method: "GET",
    url: `/api/users/${idUser}`,
    headers: {
      "x-access-token": `${token}`,
    },
  }); */
  export const requestGetUserById = (idUser: string) =>
  userLogInstance.request({
    method: "GET",
    url: `/api/users/${idUser}`
  });

  export const requestGetUserProfile = () =>
  userLogInstance.request({
    method: "GET",
    url: `/api/users/userprofile`
  });

  export const requestGetUserEdificios = () =>
  userLogInstance.request({
    method: "GET",
    url: `/api/users/edificios`
  })
