import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEVELOPMENT : process.env.REACT_APP_BASE_URL_PRODUCTION;

const informeInstance = axios.create({
  baseURL: baseURL,
});

export const requestGetInformesByTecnico = () =>
  informeInstance.request({
    method: "GET",
    url: `/api/informes/getByTecnico`,
  });

export const requestGetInformesByEdificio = (edificioId: any) =>
  informeInstance.request({
    method: "GET",
    url: `/api/informes/getByEdificio/${edificioId}`,
  });
  
export const requestGetInformeById = (informeId: any) =>
  informeInstance.request({
    method: "GET",
    url: `/api/informes/${informeId}`,
  });