import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewWillEnter,
} from '@ionic/react';
import "../../styles/App.css";
import "./ReportesCopList.css";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/ducks/rootReducer';
import React from 'react';
import { StatusBar, Style } from '@capacitor/status-bar';
import ToolbarMenu from '../../components/Toolbars/ToolbarMenu';
import { add } from 'ionicons/icons';

const ReportesCopList: React.FC = () => {
    const { _id } = useSelector((state: RootState) => state.users);
    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarMenu title="Reportes de desperfectos" />
            </IonHeader>

            <IonContent fullscreen className="ion-content-grey">
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton
                        routerLink="/copropietario/reportes/reporte-nuevo"
                        className="fab-button-reportes"
                    >
                        <IonIcon icon={add} className="ion-add-icon" />
                    </IonFabButton>
                </IonFab>
                <IonGrid></IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default ReportesCopList;
