import { action } from "typesafe-actions";
import { ObservacionTypes, IObservacion } from "./types"

export const getObservacionesByVisita = (id: string) =>
    action(ObservacionTypes.GET_OBSERVACIONES_BY_VISITA, { id });
export const setObservacionesByVisita = (observaciones: IObservacion[]) =>
    action(ObservacionTypes.SET_OBSERVACIONES_BY_VISITA, { observaciones });
export const setObservacionesByVisitaError = (mensaje: string) =>
    action(ObservacionTypes.SET_OBSERVACIONES_BY_VISITA_ERROR, { mensaje });


const actions = {
    getObservacionesByVisita: getObservacionesByVisita,
    setObservacionesByVisita: setObservacionesByVisita,
    setObservacionesByVisitaError: setObservacionesByVisitaError
};

export { actions as visitaActions };