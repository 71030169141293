import {
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonCol,
  IonRow,
  useIonViewWillEnter,
  useIonViewDidEnter,
  RefresherEventDetail,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
} from "@ionic/react";

import "../../styles/App.css";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect, useState } from "react";

import ToolbarMenu from "../../components/Toolbars/ToolbarMenu";

import { getInformesByEdificio } from "../../store/ducks/informes/actions";
import InformeListItem from "../../components/Copropietario/Informes/InformeListItem";
import { IInforme } from "../../store/ducks/informes/types";
import { getEdificioCopropietario } from "../../store/ducks/edificios/actions";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import { getObrasByEdificio } from "../../store/ducks/obras/actions";
import { checkAuthStatus } from "../../store/ducks/users/actions";


const InformeCopList: React.FC = () => {

  const { edificioCopropietario } = useSelector(
    (state: RootState) => state.edificios
  );
  const { informesByEdificio, informesLoad } = useSelector(
    (state: RootState) => state.informes
  );

  
  const idEdificio: string = edificioCopropietario?._id;
  const dispatch = useDispatch();
 
  useEffect(() => {
    console.log("informecoplist");
    if(!edificioCopropietario?._id)dispatch(getEdificioCopropietario());
  }, []);

  useEffect(() => {
    if (edificioCopropietario?._id && !informesLoad) {
      dispatch(getInformesByEdificio(edificioCopropietario._id));
      
    }
  }, [edificioCopropietario]);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      if (idEdificio) {
        dispatch(getInformesByEdificio(idEdificio));
      }
      event.detail.complete();
    }, 2000);
  }

  /*   useIonViewDidEnter(() => {
  
      if (idEdificio) {
        dispatch(getInformesByEdificio(idEdificio));
      }
  
    }, [idEdificio]); */
  return (
    <IonPage>
      <IonHeader className="ion-no-border border-header">
        <ToolbarMenu title="Informes" />
      </IonHeader>

      <IonContent fullscreen className="ion-content-grey">
        <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid className="pb-50">
          <IonRow className="dashboard-row">
         
          {!informesLoad ? (
              <IonCol size="12" className="center-content">
                <CustomSpinner color="primary"/>
              </IonCol>
            ) : informesByEdificio.length > 0 ? (
              informesByEdificio.map((informe: IInforme) => (
                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6" key={informe._id}>
                  <InformeListItem
                    link={`/copropietario/informes/${informe._id}`}
                    src={informe.imgUrl || "#"}
                    alt="informe"
                    informe={informe}
                  />
                </IonCol>
              ))
            ) : (
              <IonCol size="12" className="center-content">
                <p>No se encontraron informes.</p>
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default InformeCopList;
