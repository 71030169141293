import {
    IonCard,
    IonCardContent
} from "@ionic/react";
import "./InformeDescripcionCard.css";
import "../../General.css";

import React from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";

export type InformeDescProps = {
    descripcion?: string;
    fechaVisita?: Date;
    unidadesVisitadas?: string[];
};

const InformeDescripcionCard: React.FC<InformeDescProps> = ({
    descripcion,
    fechaVisita,
    unidadesVisitadas
}) => {
    return (
        <IonCard className="ion-card-descripcion">

            <IonCardContent>
                <div className="mb-3 mt-3 descripcion-content">
                    <div className="descripcion-txt">
                        <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Descripción</h4>
                        <p className="font-14">{descripcion}</p>
                    </div>
                    <div className="mt-3">
                        <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Fecha visita</h4>
                        <span className="font-14">
                            
                            {moment(fechaVisita).locale("es").format("LL")}
                        </span>
                    </div>
                    <div className="mt-3">
                        <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Unidades relevadas</h4>
                        <span className="font-14 text-justify">
                            {unidadesVisitadas?.join(" - ")}
                        </span>
                    </div>
                </div>

            </IonCardContent>
        </IonCard>
    );
}

export default InformeDescripcionCard;