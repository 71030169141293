import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEVELOPMENT : process.env.REACT_APP_BASE_URL_PRODUCTION;

const authAxios = axios.create({
    baseURL: baseURL
});

export const requestGetVisitasByObra = (idObra: any) =>
    authAxios.request({
        method: "GET",
        url: `/api/visitas/obra/${idObra}`,
    });

export const requestGetVisitaById = (idVisita: any) =>
    authAxios.request({
        method: "GET",
        url: `/api/visitas/visita/${idVisita}`,
    });