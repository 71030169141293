import { action } from "typesafe-actions";
import { VisitaTypes, IVisita } from "./types"

export const getVisitasByObra = (id: string) =>
    action(VisitaTypes.GET_VISITAS_BY_OBRA, { id });
export const setVisitasByObra = (visitas: IVisita[]) =>
    action(VisitaTypes.SET_VISITAS_BY_OBRA, { visitas });
export const setVisitasByObraError = (mensaje: string) =>
    action(VisitaTypes.SET_VISITAS_BY_OBRA_ERROR, { mensaje });

export const getVisitaById = (id: string) =>
    action(VisitaTypes.GET_VISITA_BY_ID, { id });
export const setVisitaById = (visita: IVisita) =>
    action(VisitaTypes.SET_VISITA_BY_ID, { visita });
export const setVisitaByIdError = (mensaje: string) =>
    action(VisitaTypes.SET_VISITA_BY_ID_ERROR, { mensaje });

const actions = {
    getVisitasByObra: getVisitasByObra,
    setVisitasByObra: setVisitasByObra,
    setVisitasByObraError: setVisitasByObraError,
    getVisitaById: getVisitaById,
    setVisitaById: setVisitaById,
    setVisitaByIdError: setVisitaByIdError
};

export { actions as visitaActions };    