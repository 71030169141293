import { action } from "typesafe-actions";
import { EdificioTypes, IEdificio } from "./types";
import { IInforme } from "../informes/types";

export const getEdificios = () => action(EdificioTypes.GET_EDIFICIOS);
export const setEdificios = (edificios: [IEdificio]) =>
  action(EdificioTypes.SET_EDIFICIOS, { edificios });

export const getEdificioInformes = (id: string) => action(EdificioTypes.GET_EDIFICIO_INFORMES, { id });
export const setEdificioInfomes = (informes: [IInforme]) =>
  action(EdificioTypes.SET_EDIFICIO_INFORMES, { informes });

export const getEdificiosUnread = () => action(EdificioTypes.GET_EDIFICIOS_UNREAD);
export const setEdificiosUnread = (edificios: [IEdificio]) =>
  action(EdificioTypes.SET_EDIFICIOS_UNREAD, { edificios });

export const getEdificioById = (id: string) =>
  action(EdificioTypes.GET_EDIFICIO_BY_ID, { id });
export const setEdificioById = (edificio: IEdificio) =>
  action(EdificioTypes.SET_EDIFICIO_BY_ID, { edificio });

export const getEdificioCopropietario = () =>
  action(EdificioTypes.GET_EDIFICIO_COPROPIETARIO);
export const setEdificioCopropietario = (edificio: IEdificio) =>
  action(EdificioTypes.SET_EDIFICIO_COPROPIETARIO, { edificio });

export const postEdificioRequest = (edificio: FormData) =>
  action(EdificioTypes.POST_EDIFICIO_REQUEST, { edificio });
export const postEdificioSuccess = (edificio: IEdificio, message: string) =>
  action(EdificioTypes.POST_EDIFICIO_SUCCESS, { edificio, message });

export const postEdificioFail = (message: string) =>
  action(EdificioTypes.POST_EDIFICIO_FAIL, { message });

export const setEdificioErrorMessage = (message: string) =>
  action(EdificioTypes.SET_EDIFICIO_ERROR_MESSAGE, { message });

const actions = {
  getEdificios: getEdificios,
  setEdificios: setEdificios,
  getEdificiosUnread: getEdificiosUnread,
  setEdificiosUnread: setEdificiosUnread,
  postEdificioRequest: postEdificioRequest,
  postEdificioSuccess: postEdificioSuccess,
  postEdificioFail: postEdificioFail,
  getEdificioById: getEdificioById,
  setEdificioById: setEdificioById,
  setEdificioErrorMessage: setEdificioErrorMessage,
  getEdificioInformes:getEdificioInformes,
  setEdificioInfomes:setEdificioInfomes

};

export { actions as edificioActions };
