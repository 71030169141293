import axios from "axios";
import { IUserRequest } from "../store/ducks/users/types";
import { CapacitorHttp } from "@capacitor/core";

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEVELOPMENT : process.env.REACT_APP_BASE_URL_PRODUCTION;

const userInstance = axios.create({
  baseURL: baseURL,
});

export const signIn = (user: IUserRequest) =>
  userInstance.request({
    method: "POST",
    data: user,
    url: `/api/auth/login`
  });
export const checkAuth = () =>
  userInstance.request({
    method: "GET",
    url: `/api/auth/check-auth`
  });
/*   export const signIn = (user: FormData) =>
    userInstance.request({
      method: "POST",
      data: user,
      url: `/api/auth/login`
    }); */
export const logoutApi = () =>
  userInstance.request({
    method: "GET",
    url: `/api/auth/logout`
  });





/* userInstance.request({
    method: "GET",
    url: `/api/auth/logged`,
  }); */
