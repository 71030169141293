import React from 'react';
import { IonSpinner } from '@ionic/react';
import './CustomSpinner.css';

interface CustomSpinnerProps {
  name?: string | undefined;
  color?: string;
}

const CustomSpinner: React.FC<CustomSpinnerProps> = ({color}) => {
  return (
    
      <IonSpinner color={color} name="crescent" className="custom-spinner"/>
    
  );
};

export default CustomSpinner;