import { call, put, takeLatest } from "redux-saga/effects";

import { requestGetInformesByEdificio, requestGetInformesByTecnico, requestGetInformeById } from "../../../api/informes";

import { setInformesByEdificio, setInformesByTecnico, setInformeById } from "./actions";

import { IInforme, InformeTypes, getInformesByEdificioAction, getInformeByIdAction } from "./types";

function* getInformesByTecnico() {
  try {
    const { data } = yield call(requestGetInformesByTecnico);
    const informesResult: [IInforme] = data;
    yield put(setInformesByTecnico(informesResult));
  } catch (error) {
    console.log(error);
  }
}

function* getInformesByEdificio({ payload }: getInformesByEdificioAction) {
  try {
    const _id = payload.id;
    const { data } = yield call(requestGetInformesByEdificio, _id);
    console.log(data);
    const informesResult: [IInforme] = data.informes;

    yield put(setInformesByEdificio(informesResult));
  } catch (error) {
    console.log(error);
  }
}

function* getInformeById({ payload }: getInformeByIdAction) {
  try {
    const _id = payload.id;
    const { data } = yield call(requestGetInformeById, _id);
    const informeResult: IInforme = data.informe;
    console.log("informeResult");
    console.log(data);
    yield put(setInformeById(informeResult));
  } catch (error) {
    console.log(error);
  }
}

export function* informesWatcherGetInformesByTecnico() {
  yield takeLatest(InformeTypes.GET_INFORMES_BY_TECNICO, getInformesByTecnico);
}

export function* informesWatcherGetInformesByEdificio() {
  yield takeLatest(InformeTypes.GET_INFORMES_BY_EDIFICIO, getInformesByEdificio);
}

export function* informesWatcherGetInformeById() {
  yield takeLatest(InformeTypes.GET_INFORME_BY_ID, getInformeById);
}

