import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./ducks/rootReducer";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./ducks/rootSaga";

export default function configureMainStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware] ,
    devTools: process.env.NODE_ENV !== "production"
  });
  sagaMiddleware.run(rootSaga);
  return { store };
}
