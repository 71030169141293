import { IEdificio } from "../edificios/types"
import { IObra } from "../obras/types";

/**
 * types
 */
export enum VisitaTypes {
    /*GET VISITAS BY OBRA*/
    GET_VISITAS_BY_OBRA = "GET_VISITAS_BY_OBRA",
    SET_VISITAS_BY_OBRA = "SET_VISITAS_BY_OBRA",
    SET_VISITAS_BY_OBRA_ERROR = "SET_VISITAS_BY_OBRA_ERROR",
     /*GET VISITA BY ID*/
     GET_VISITA_BY_ID = "GET_VISITA_BY_ID",
     SET_VISITA_BY_ID = "SET_VISITA_BY_ID",
     SET_VISITA_BY_ID_ERROR = "SET_VISITA_BY_ID_ERROR",
    /*LOGOUT*/
    LOGOUT = "LOGOUT"
}

/**
 * Data types
 */

export interface IVisita {
    _id?: any;
    obra?: IObra;
    titulo?: string;
    resumen?: string;
    responsablePresente?: string;
    avance?: number;
    fechaVisita?: Date;
    observaciones?: any[];
    relevamientoFotografico?: any[];
    notas?: any[];
    imgUrl?: string;
    empresa?: any;
    presupuesto?: any;
    recaudosGraficos?: any[];
    visitas?: any[];
    createdBy?: any;
    updatedBy?: any;
    activo?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    informeVisitaUrl?: string;
}

/**
 * State type
 */
export interface VisitaState {
    visitasLoad: boolean;
    visitaByIdLoad: boolean;
    visitasByObra: IVisita[] | [];
    visitaById: IVisita;
    message: string;
    errorMessage: string;
}

/**
 * Action type
 */
//Get Visitas By Obra
export interface GetVisitasByObraAction {
    type: typeof VisitaTypes.GET_VISITAS_BY_OBRA;
    payload: { id: string }
}
export interface SetVisitasByObraAction {
    type: typeof VisitaTypes.SET_VISITAS_BY_OBRA;
    payload: { visitas: IVisita[] }
}
export interface SetVisitasByObraErrorAction {
    type: typeof VisitaTypes.SET_VISITAS_BY_OBRA_ERROR;
    payload: { mensaje: string };
}
//Get Visita By Id
export interface GetVisitaByIdAction {
    type: typeof VisitaTypes.GET_VISITA_BY_ID;
    payload: { id: string }
}
export interface SetVisitaByIdAction {
    type: typeof VisitaTypes.SET_VISITA_BY_ID;
    payload: { visita: IVisita }
}
export interface SetVisitaByIdErrorAction {
    type: typeof VisitaTypes.SET_VISITA_BY_ID_ERROR;
    payload: { mensaje: string };
}
//Logout
export interface LogoutAction {
    type: typeof VisitaTypes.LOGOUT;
}


export type VisitaActionTypes =
    | GetVisitasByObraAction
    | SetVisitasByObraAction
    | SetVisitasByObraErrorAction
    | GetVisitaByIdAction
    | SetVisitaByIdAction
    | SetVisitaByIdErrorAction
    | LogoutAction
 