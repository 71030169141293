import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewWillEnter,
  } from '@ionic/react';
  import "../../styles/App.css";
  import { useSelector } from 'react-redux';
  import { RootState } from '../../store/ducks/rootReducer';
  import React from 'react';
  import { StatusBar, Style } from '@capacitor/status-bar';
  import ToolbarMenu from '../../components/Toolbars/ToolbarMenu';
  
  const PresupuestosCopList: React.FC = () => {
    const { _id } = useSelector((state: RootState) => state.users);
    return (
      <IonPage>
        <IonHeader className="ion-no-border border-header">
          <ToolbarMenu title="Presupuestos" />
        </IonHeader>
  
        <IonContent fullscreen className="ion-content-grey">
          <IonGrid></IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default PresupuestosCopList;
  