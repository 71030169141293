import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonImg,
} from "@ionic/react";

import "../../General.css";
import "./VisitaListItem.css";
import { arrowForwardCircleOutline, chevronForwardOutline } from "ionicons/icons";
import React from "react";
import { IVisita } from "../../../store/ducks/visitas/types";
import { FiCalendar } from "react-icons/fi";
import moment from "moment";

export type VisitaListItemsProps = {
  alt: string;
  link: string;
  visita: IVisita // Nueva propiedad para la URL de la imagen
};

const VisitaListItem: React.FC<VisitaListItemsProps> = ({
  link,
  alt,
  visita
}) => {
  return (
    <IonCard className="ion-card-menu" routerLink={link} routerDirection="root">
      <IonCardContent>
        <div className="d-flex align-items-center">
          {/* Imagen a la izquierda */}
          <div className="img-visita-item-box">
            <img alt={alt} src={visita?.imgUrl} className="img-visita-item" />
          </div>
          {/* Contenido a la derecha */}
          <div className="content-visita-item ml-3">
            {/* Fecha */}
            <span className="font-13 mb-1 d-flex align-items-center">
            
              {moment(visita?.fechaVisita).locale("es").format("LL")}
            </span>
            {/* Título */}
            <h4 className="font-weight-500 font-16 text-capitalize font-dark-grey mb-1">
              {visita.titulo}
            </h4>

        
            <div className="icon-visita-item ml-auto">
              <IonIcon icon={arrowForwardCircleOutline}></IonIcon>
            </div>
          </div>

        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default VisitaListItem;