import { Reducer } from "redux";
import { ObraTypes, ObraState, ObraActionTypes } from "./types";

const initialState: ObraState = {
    obrasLoad: false,
    obraByIdLoad: false,
    inPromise: false,
    obras: [],
    obraById: {},
    obrasUnread: [],
    message: "",
    errorMessage: ""
};

export const obraReducer: Reducer<ObraState> = (
    state = initialState,
    action: ObraActionTypes
) => {
    switch (action.type) {
        case ObraTypes.GET_OBRAS_BY_EDIFICIO: return {
            ...state,
            inPromise: true,
            obrasLoad: false,
            message: "Cargando..."
        };
        case ObraTypes.SET_OBRAS_BY_EDIFICIO:
             return {
            ...state,
            obrasLoad: true,
            inPromise: false,
            obras: action.payload.obras,
            message: "Obras cargadas exitosamente",
        };
        case ObraTypes.GET_OBRA_BY_ID: return {
            ...state,
            inPromise: true,
            obraByIdLoad: false,
            message: "Cargando..."
        };
        case ObraTypes.SET_OBRA_BY_ID: 
        
             return {
            ...state,
            obraByIdLoad: true,
            inPromise: false,
            obraById: action.payload.obra,
            message: "Obra cargada exitosamente",
        };
        case ObraTypes.LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};