import { jwtDecode } from "jwt-decode";




// Eliminar la cookie "access_token" para cerrar sesión

const isTokenExpired = (): boolean => {
  try {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    console.log(tokenExpiration);

    if (!tokenExpiration) {
      // Si no hay tokenExpiration, considera el token como expirado
      console.log("No tokenExpiration found");
      return true;
    }

    const numTokenExpiration = parseInt(tokenExpiration, 10);

    // Verifica si el token ha expirado
    if (Date.now() >= numTokenExpiration) {

      return true;
    }

    // Si el token no ha expirado
    return false;

  } catch (e) {
    console.log("Error in isTokenExpired:", e);
    // En caso de error, considera el token como expirado
    return true;
  }
};




const getUserId = () => {
  const id = localStorage.getItem("userId");
  if (id !== null) return id
  else return "";
};






export {  isTokenExpired, getUserId};
