import ReactDOM from "react-dom/client";
import App from "./App";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider, useDispatch } from "react-redux";
import configureStore from "./store";
import { isPlatform } from "@ionic/react";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { setNewContentAvailable } from "./store/ducks/users/actions";

const { store } = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Función para verificar si el plugin de la barra de estado está disponible
const isStatusBarAvailable = () => Capacitor.isPluginAvailable('StatusBar');

// Intentamos configurar la barra de estado para plataformas móviles
try {
    if ((isPlatform("android") || isPlatform("ios")) && isStatusBarAvailable()) {
        StatusBar.setBackgroundColor({ color: "#edf0f5" }); // Color de fondo
        StatusBar.setStyle({ style: Style.Light }); // Estilo de la barra de estado (claro)
    } else {
        console.log("StatusBar plugin is not implemented on this platform.");
    }
} catch (error) {
    console.log("Error al configurar StatusBar:", error);
}



root.render(<Provider store={store}><App /></Provider>);

defineCustomElements(window);

// Al registrar el Service Worker

if (process.env.NODE_ENV === 'development') {
    serviceWorkerRegistration.unregister();
} else {
   
    serviceWorkerRegistration.register({
        onUpdate: () => {
          
            store.dispatch(setNewContentAvailable());
        }
    });
} 