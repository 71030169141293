import React, { Suspense, useCallback, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { IonReactRouter } from "@ionic/react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IonApp, IonRouterOutlet, setupIonicReact, IonSplitPane, IonToast, useIonViewWillEnter, IonLoading, isPlatform, useIonRouter } from "@ionic/react";
/* PrivateRoutes */
import PrivateRoutes from "./routes/PrivateRoute";
/* Utils */
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
import "./theme/global.css";
/* Pages */
import Login from "./pages/Login/Login";
/* Types */
import { IUserSuccess } from "./store/ducks/users/types";
/* Actions */
import { logInSuccess, getUserProfileRequest, checkAuthStatus, logout, setNewContentAvailable, resetNewContentAvailable } from "./store/ducks/users/actions";
/* JWT */
import { jwtDecode } from 'jwt-decode'

import LoginRoute from "./routes/LoginRoute";

import { RootState } from "./store/ducks/rootReducer";

import Perfil from "./pages/Perfil/Perfil";

import { App as CapacitorApp } from '@capacitor/app';
import CenteredLoader from './components/CenteredLoader/CenteredLoader';
import { getUserId } from './utils/auth';
import NotAuthorized from './pages/NotAuthorized/NotAuthorized';
import Main from './pages/Main';
//const Main = React.lazy(() => import('./pages/Main'));





setupIonicReact();

const App: React.FC = () => {

  /* const { isLogged, tipo, newContentAvailable } = useSelector((state: RootState) => state.users);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [toastIsOpen, setToastIsOpen] = useState(false);
  const dispatch = useDispatch();

  const pathByType = (tipo: string) => {
    let routePath: string = "";
    if (tipo === "copropietario")
      routePath = "/copropietario";
    if (tipo === "tecnico")
      routePath = "/tecnico";
    return routePath
  };

  const handleReload = () => {
    setToastIsOpen(false);
    dispatch(resetNewContentAvailable());
    reloadPage();
  };
  const reloadPage = useCallback(() => {

    window.location.reload();
  }, []);

  useEffect(() => {
    const backButtonListener = CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        // En la página de inicio, salir de la app
        CapacitorApp.exitApp();
      }
    });
    return () => {
      // Asegurarse de limpiar el listener al desmontar el componente
      backButtonListener.remove();
    };
  }, []);

  useEffect(() => {
    console.log(window.location);
    dispatch(checkAuthStatus());
  }, []);

  useEffect(() => {
    // If profile is not loaded, request it
    if (newContentAvailable) {
      setToastIsOpen(true);
    }
  }, [newContentAvailable]);

  useEffect(() => {
    // If profile is not loaded, request it
    if (!profileLoaded && isLogged) {
      dispatch(getUserProfileRequest());
      setProfileLoaded(true);
    }
  }, [isLogged, profileLoaded]); */

  return (

    <IonApp>
      <IonReactRouter>
      
          <Main />
        
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
