import { action } from "typesafe-actions";
import { ObraTypes, IObra } from "./types"

export const getObrasByEdificio = (id: string) => action(ObraTypes.GET_OBRAS_BY_EDIFICIO, { id });
export const setObrasByEdificio = (obras: IObra[]) =>
    action(ObraTypes.SET_OBRAS_BY_EDIFICIO, { obras });
export const getObraById = (id: string) => action(ObraTypes.GET_OBRA_BY_ID, { id });
export const setObraById = (obra: IObra) =>
    action(ObraTypes.SET_OBRA_BY_ID, { obra });

const actions = {
    getObrasByEdificio: getObrasByEdificio,
    setObrasByEdificio: setObrasByEdificio,
    getObraById: getObraById,
    setObraById: setObraById
};

export { actions as obraActions };    