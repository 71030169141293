import { Reducer } from "redux";
import { ObservacionTypes, ObservacionState, ObservacionActionTypes } from "./types";

const initialState: ObservacionState = {
    inPromise: false,
    observacionesLoad: false,
    observacionByIdLoad: false,
    observacionesByVisita: [],
    message: "",
    observacionById: {},
    errorMessage: ""
}

export const observacionReducer: Reducer<ObservacionState> = (
    state = initialState,
    action: ObservacionActionTypes
) => {
    switch (action.type) {
        case ObservacionTypes.GET_OBSERVACIONES_BY_VISITA: return {
            ...state,
            inPromise: true,
            observacionesLoad: false,
            message: "Cargando..."
        };
        case ObservacionTypes.SET_OBSERVACIONES_BY_VISITA:
            return {
                ...state,
                observacionesLoad: true,
                inPromise: false,
                observacionesByVisita: action.payload.observaciones,
                message: "Observaciones cargadas exitosamente",
            };
        case ObservacionTypes.SET_OBSERVACIONES_BY_VISITA_ERROR:
            return {
                ...state,
                inPromise: false,
                errorMessage: action.payload.mensaje
            };
 
        case ObservacionTypes.LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
}