import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEVELOPMENT : process.env.REACT_APP_BASE_URL_PRODUCTION;

const edificioInstance = axios.create({
  baseURL: baseURL
});

export const requestGetEdificios = () =>
  edificioInstance.request({
    method: "GET",
    url: "/api/edificios"
  });
  export const requestGetEdificioCopropietario = () =>
  edificioInstance.request({
    method: "GET",
    url: "/api/edificios/copropietario"
  });
export const requestGetEdificiosUnreaded = () =>
  edificioInstance.request({
    method: "GET",
    url: "/api/edificios/nuevos"
  });

export const requestGetEdificioById = (idEdificio: any) =>
  edificioInstance.request({
    method: "GET",
    url: `/api/edificios/${idEdificio}`
  });

export const requestGetEdificioObras = (idEdificio: any) => edificioInstance.request({
  method: 'GET',
  url: `/api/edificios/${idEdificio}/obras`
});

export const requestPostEdificio = (edificio: FormData) =>
  edificioInstance.request({
    method: "POST",
    data: edificio,
    url: `/api/edificios`
  });

export const requestPostEdificioObra = (idEdificio: any, obra: any) =>
  edificioInstance.request({
    method: "POST",
    data: obra,
    url: `/api/edificios/${idEdificio}/obras`
  });
