import React from 'react';
import { IonPage, IonContent, IonLoading } from '@ionic/react';
import './CenteredLoader.css'; // Archivo CSS para centrar el loader

interface CenteredLoaderProps {
  isOpen: boolean;
  message: string
}

const CenteredLoader: React.FC<CenteredLoaderProps> = ({ isOpen, message }) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="centered-loader">
          <IonLoading isOpen={isOpen} duration={3000} message={message} spinner="circles" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CenteredLoader;