import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewDidEnter,
    useIonViewWillEnter,
} from "@ionic/react";
import "../../styles/App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { getInformeById } from "../../store/ducks/informes/actions";
import { getObraById } from "../../store/ducks/obras/actions";
import { getVisitaById, getVisitasByObra } from "../../store/ducks/visitas/actions";
import ObraListItem from "../../components/Copropietario/Obras/ObraListItem";
import CustomSpinner from "../../components/Spinner/CustomSpinner";
import { IObra } from "../../store/ducks/obras/types";
import { IVisita } from "../../store/ducks/visitas/types";
import VisitaListItem from "../../components/Copropietario/Visitas/VisitaListItem";
import VisitaDescripcionCard from "../../components/Copropietario/Visitas/VisitaDescriptionCard";
import { getObservacionesByVisita } from "../../store/ducks/observaciones/actions";
import VisitaDetallesItem from "../../components/Copropietario/Visitas/VisitaDetallesItem";
import { alertCircleOutline, clipboardOutline } from "ionicons/icons";
import { IObservacion } from "../../store/ducks/observaciones/types";
import ObservacionDetallesCard from "../../components/Copropietario/Observaciones/ObservacionesDetallesCard";
import GaleriaFotos from "../../components/GaleriaFotos/GaleriaFotos";

type Params = { obraId: string | undefined, visitaId: string | undefined };

const ObraCopVisitaObservaciones: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { obraId, visitaId }
    },
}) => {
    const { _id, userProfile } = useSelector((state: RootState) => state.users);
    const { obraByIdLoad, obraById } = useSelector((state: RootState) => state.obras);
    const { visitasByObra, visitasLoad, visitaById, visitaByIdLoad } = useSelector((state: RootState) => state.visitas);

    const { observacionesByVisita, observacionesLoad } = useSelector((state: RootState) => state.observaciones);

    const dispatch = useDispatch();
    useEffect(() => {
        console.log(visitaById);
        if (visitaId && !observacionesLoad) dispatch(getObservacionesByVisita(visitaId));
        console.log(observacionesByVisita);
    }, [observacionesByVisita]);



    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title="Observaciones" backLink={`/copropietario/obras/seguimiento/${obraId}/visitas/${visitaId}`} />
            </IonHeader>

            <IonContent fullscreen className="ion-content-grey">

                <IonGrid className="pb-50">
                    <IonRow className="dashboard-row">
                        {!observacionesLoad ? (
                            <IonCol size="12" className="center-content">
                                <CustomSpinner color="primary" />
                            </IonCol>
                        ) : observacionesByVisita.length > 0 ? (
                            observacionesByVisita.map((observacion: IObservacion) => (
                                <IonCol size="12" key={observacion._id}>
                                    <ObservacionDetallesCard
                                        observacion={observacion}
                                    />
                                   
                                </IonCol>
                            ))
                        ) : (
                            <IonCol size="12" className="center-content">
                                <p>No se realizaron observaciones.</p>
                            </IonCol>
                        )}

                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
};

export default ObraCopVisitaObservaciones;