import {
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
} from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import ToolbarBack from "../../components/Toolbars/ToolbarBack";
import { RootState } from "../../store/ducks/rootReducer";
import "../Dashboard/Dashboard.css";
import "../../styles/App.css";

const Perfil: React.FC = () => {
  const { _id, userProfile } = useSelector((state: RootState) => state.users);
  return (
    <IonPage className="bg-color-grey">
      <IonHeader className="ion-no-border border-header">
         <ToolbarBack title="Perfil" backLink="/app" />
      </IonHeader>

      <IonContent fullscreen className="ion-content-grey">
      
      </IonContent>
    </IonPage>
  );
};

export default Perfil;
