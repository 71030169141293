import { IEdificio } from "../edificios/types"
import { IVisita } from "../visitas/types";

/**
 * types
 */
export enum ObservacionTypes {
    /*GET OBSERVACIONES BY VISITA*/
    GET_OBSERVACIONES_BY_VISITA = "GET_OBSERVACIONES_BY_VISITA",
    SET_OBSERVACIONES_BY_VISITA = "SET_OBSERVACIONES_BY_VISITA",
    SET_OBSERVACIONES_BY_VISITA_ERROR = "SET_OBSERVACIONES_BY_VISITA_ERROR",

    /*LOGOUT*/
    LOGOUT = "LOGOUT"
}

/**
 * Data types
 */
/**
 * Imagen de una obra
 */
export interface ImagenObra {
    _id?:any;
    imgUrl: string; // Es requerido en el esquema
    comentario?: string; // Es opcional, tiene un valor por defecto en el esquema
    createdAt?: Date; // Añadido por timestamps de Mongoose
    updatedAt?: Date; // Añadido por timestamps de Mongoose
}

export interface IObservacion {
    _id?: any;
    visista?: IVisita;
    titulo?: string;
    observacion?: string;
    nivel?: string;
    unidad?: string;
    rubro?: string;
    sector?: string;
    urgencia?: boolean;
    estado?: string;
    imagenesGaleria?: ImagenObra[];
    imgUrl?: string;
    createdBy?: any;
    updatedBy?: any;
    activo?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}

/**
 * State type
 */
export interface ObservacionState {
    inPromise: boolean,
    observacionesLoad: boolean;
    observacionByIdLoad: boolean;
    observacionesByVisita: IObservacion[] | [];
    observacionById: IObservacion;
    message: string;
    errorMessage: string;
}

/**
 * Action type
 */
//Get Observaciones By Visita
export interface GetObservacionesByVisitaAction {
    type: typeof ObservacionTypes.GET_OBSERVACIONES_BY_VISITA;
    payload: { id: string }
}
export interface SetObservacionesByVisitaAction {
    type: typeof ObservacionTypes.SET_OBSERVACIONES_BY_VISITA;
    payload: { observaciones: IObservacion[] }
}
export interface SetObservacionesByVisitaErrorAction {
    type: typeof ObservacionTypes.SET_OBSERVACIONES_BY_VISITA_ERROR;
    payload: { mensaje: string };
}

//Logout
export interface LogoutAction {
    type: typeof ObservacionTypes.LOGOUT;
}


export type ObservacionActionTypes =
    | GetObservacionesByVisitaAction
    | SetObservacionesByVisitaAction
    | SetObservacionesByVisitaErrorAction
    | LogoutAction
 