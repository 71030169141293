import {
  call,
  put,
  takeLatest,
} from "redux-saga/effects";
import { requestGetVisitaById, requestGetVisitasByObra } from "../../../api/visitas";

import {
  setVisitaById,
  setVisitaByIdError,
  setVisitasByObra, setVisitasByObraError
} from "./actions";
import { VisitaTypes, GetVisitasByObraAction, IVisita, GetVisitaByIdAction } from "./types";

function* getVisitasByObraRequest({ payload }: GetVisitasByObraAction) {
  try {
    const idObra = payload.id;
    const { data } = yield call(requestGetVisitasByObra, idObra);
    const visitasResult: IVisita[] = data.visitas;
    yield put(setVisitasByObra(visitasResult));
  } catch (error: any) {
    console.error("Error al obtener las visitas:", error);

    // Verifica si el error tiene una respuesta del servidor
    if (error.response) {
      const statusCode = error.response.status;
      const errorMessage = error.response.data?.message || 'Error desconocido del servidor';

      // Manejar errores específicos de la respuesta HTTP
      if (statusCode === 404) {
        yield put(setVisitasByObraError('La obra no existe.'));
      } else if (statusCode === 500) {
        yield put(setVisitasByObraError('Error interno del servidor. Intente más tarde.'));
      } else {
        yield put(setVisitasByObraError(errorMessage));
      }
    } else {
      // Manejar otros tipos de errores (ejemplo: problemas de red)
      yield put(setVisitasByObraError('Error de conexión. Verifique su red.'));
    }
  }
}

function* getVisitaByIdRequest({ payload }: GetVisitaByIdAction) {
  try {
    const idVisita = payload.id;
    const { data } = yield call(requestGetVisitaById, idVisita);
    console.log(data);
    const visitaResult: IVisita = data.visita;
    yield put(setVisitaById(visitaResult));
  } catch (error: any) {
    console.error("Error al obtener la visita:", error);

    // Verifica si el error tiene una respuesta del servidor
    if (error.response) {
      const statusCode = error.response.status;
      const errorMessage = error.response.data?.message || 'Error desconocido del servidor';

      // Manejar errores específicos de la respuesta HTTP
      if (statusCode === 404) {
        yield put(setVisitaByIdError('La visita no existe.'));
      } else if (statusCode === 500) {
        yield put(setVisitaByIdError('Error interno del servidor. Intente más tarde.'));
      } else {
        yield put(setVisitaByIdError(errorMessage));
      }
    } else {
      // Manejar otros tipos de errores (ejemplo: problemas de red)
      yield put(setVisitaByIdError('Error de conexión. Verifique su red.'));
    }
  }
}

export function* visitasWatcherGetVisitasByObra() {
  yield takeLatest(VisitaTypes.GET_VISITAS_BY_OBRA, getVisitasByObraRequest);
}

export function* visitasWatcherGetVisitaById() {
  yield takeLatest(VisitaTypes.GET_VISITA_BY_ID, getVisitaByIdRequest);
}