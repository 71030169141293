import {
  call,
  put,
  takeLatest,
} from "redux-saga/effects";
import { requestGetObservacionesByVisita } from "../../../api/observaciones";

import {
  setObservacionesByVisita,
  setObservacionesByVisitaError
} from "./actions";
import { ObservacionTypes, GetObservacionesByVisitaAction, IObservacion } from "./types";

function* getObservacionesByVisitaRequest({ payload }: GetObservacionesByVisitaAction) {
  try {
    const idVisita = payload.id;
    const { data } = yield call(requestGetObservacionesByVisita, idVisita);
    const observacionesResult: IObservacion[] = data.observaciones;
    yield put(setObservacionesByVisita(observacionesResult));
  } catch (error: any) {
    console.error("Error al obtener las observaciones:", error);

    // Verifica si el error tiene una respuesta del servidor
    if (error.response) {
      const statusCode = error.response.status;
      const errorMessage = error.response.data?.message || 'Error desconocido del servidor';

      // Manejar errores específicos de la respuesta HTTP
      if (statusCode === 404) {
        yield put(setObservacionesByVisitaError('La observacion no existe.'));
      } else if (statusCode === 500) {
        yield put(setObservacionesByVisitaError('Error interno del servidor. Intente más tarde.'));
      } else {
        yield put(setObservacionesByVisitaError(errorMessage));
      }
    } else {
      // Manejar otros tipos de errores (ejemplo: problemas de red)
      yield put(setObservacionesByVisitaError('Error de conexión. Verifique su red.'));
    }
  }
}


export function* observacionesWatcherGetObservacionesByVisita() {
  yield takeLatest(ObservacionTypes.GET_OBSERVACIONES_BY_VISITA, getObservacionesByVisitaRequest);
}

