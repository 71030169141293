import {
    call,
    put,
    takeLatest,
} from "redux-saga/effects";
import { requestGetObraById, requestGetObrasByEdificio } from "../../../api/obras";
import { setObraById, setObrasByEdificio } from "../obras/actions";
import { ObraTypes, GetObrasAction, IObra, GetObraByIdAction } from "../obras/types";


function* getObrasByEdificioRequest({ payload }: GetObrasAction) {
    try {
        const idEdificio = payload.id;
        const { data } = yield call(requestGetObrasByEdificio, idEdificio);
        const obrasResult: IObra[] = data.obras;
        yield put(setObrasByEdificio(obrasResult));
    } catch (error: any) {
        console.log(error)
        if (error.response) {
            console.log(error)
        }
    }
}

function* getObraByIdRequest({ payload }: GetObraByIdAction) {
    try {
      const _id = payload.id;
      const { data } = yield call(requestGetObraById, _id);
      const obraResult: IObra = data;
      yield put(setObraById(obraResult));
    } catch (error) {
      console.log(error);
    }
  }

export function* obrasWatcherGetObrasByEdificio() {
    yield takeLatest(ObraTypes.GET_OBRAS_BY_EDIFICIO, getObrasByEdificioRequest);

}
export function* obrasWatcherGetObraById() {
    yield takeLatest(ObraTypes.GET_OBRA_BY_ID, getObraByIdRequest);

}