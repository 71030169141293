import { Reducer } from "redux";
import { InformeTypes, InformeState } from "./types";

const initialState: InformeState = {
  informesLoad: false,
  informeDetailLoad: false,
  inPromise: false,
  informesByTecnico: [],
  informesByEdificio: [],
  informesByTecnicoBorrador: [],
  informesUnread: [],
  message: "",
  errorMessage: "",
  informeById: { nombreArchivo: "", titulo: "", imgArchivoUrl: "#", pdfUrl: "#" }
};

export const informeReducer: Reducer<InformeState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case InformeTypes.GET_INFORMES_BY_EDIFICIO:
      return {
        ...state,
        inPromise: true,
        informesLoad: false,
        message: "",
        errorMessage: ""
      };
    case InformeTypes.SET_INFORMES_BY_EDIFICIO:
      return {
        ...state,
        informesLoad: true,
        informesByEdificio: action.payload.informes,
        inPromise: false
      };
    case InformeTypes.GET_INFORME_BY_ID:
      return {
        ...state,
        inPromise: true,
        message: "",
        errorMessage: ""
      };
    case InformeTypes.SET_INFORME_BY_ID:
      return {
        ...state,
        informeById: action.payload.informe,
        informeDetailLoad: true,
        inPromise: false
      };
    case InformeTypes.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};
