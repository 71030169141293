import {
    IonCard,
    IonCardContent
} from "@ionic/react";

import "../../General.css";

import React from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";
import { IObra } from "../../../store/ducks/obras/types";
import { IVisita } from "../../../store/ducks/visitas/types";

export type VisitaProps = {
    visita?: IVisita
};

const VisitaDescripcionCard: React.FC<VisitaProps> = ({
    visita
}) => {
    return (
        <IonCard className="ion-card-descripcion">

            <IonCardContent>
                <div className="mt-3 mb-3">
                    {visita?.resumen && (<div className="descripcion-txt">
                        <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Resumen</h4>
                        <p className="font-14">{visita?.resumen}</p>
                    </div>)}
                    {visita?.fechaVisita && (
                        <div className="mt-3">
                            <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Fecha Visita</h4>
                            <span className="font-14">

                                {moment(visita?.fechaVisita).locale("es").format("LL")}
                            </span>
                        </div>
                    )}
                    {/*  {visita?.avance && (
                        <div className="mt-3">
                            <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Avance</h4>
                            <span className="font-14">
                                {visita.avance.toString()}
                            </span>
                        </div>
                    )}
 */}

                </div>

            </IonCardContent>
        </IonCard>
    );
}

export default VisitaDescripcionCard;