import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
  } from "@ionic/react";
  import "./VisitaDetallesItem.css";
  import "../../General.css";
  import { businessOutline, chevronForwardOutline, arrowForwardOutline } from 'ionicons/icons';
  import React, { ReactElement } from "react";
  import moment from "moment";
  import "moment/locale/es";
  import { FiCalendar } from "react-icons/fi";
  
  export type VisitaDetallesItemsProps = {
    title: string;
    subTitle: string;
    link: string;
    iconName: string;
  };
  
  const VisitaDetallesItem: React.FC<VisitaDetallesItemsProps> = ({
    link,
    title,
    subTitle,
    iconName
  }) => {
    return (
      <IonCard className="ion-card-menu" routerLink={link} routerDirection="root">
  
        <IonCardContent>
          <div className="d-flex align-items-center obra-item-box">
            <IonIcon icon={iconName} className="ion-icon-obra-item float-left"></IonIcon>
            <div className="obra-item-text">
              <h4 className="txt-obra-item-card text-capitalize font-dark-grey float-left ml-2">
                {title}
              </h4>
              <p className="ml-2">
                {subTitle}
              </p>
            </div>
  
            <IonIcon icon={chevronForwardOutline} className="ion-icon-obra-forward float-right"></IonIcon>
          </div>
  
        </IonCardContent>
      </IonCard>
    );
  }
  
  export default VisitaDetallesItem;