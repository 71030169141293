import {
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonPage,
    IonRow,
    useIonViewWillEnter,
    useIonViewWillLeave,
} from "@ionic/react";
import "../../styles/App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/ducks/rootReducer";
import React, { useEffect, useState } from "react";

import { RouteComponentProps } from "react-router";

import { businessOutline, listOutline, walletOutline, documentAttachOutline, documentAttach, list, wallet, briefcase, clipboard, images, reader, documentText, clipboardOutline, briefcaseOutline, cashOutline, readerOutline, imagesOutline, documentTextOutline } from "ionicons/icons";

import ToolbarBack from "../../components/Toolbars/ToolbarBack";

import { getObraById } from "../../store/ducks/obras/actions";
import ObraItem from "../../components/Copropietario/Obras/ObraItem";
import ObraDescripcionCard from "../../components/Copropietario/Obras/ObraDescripcionCard";
import CustomSpinner from "../../components/Spinner/CustomSpinner";

type Params = { obraId: string };

const ObraCopDetail: React.FC<RouteComponentProps<Params>> = ({
    match: {
        params: { obraId },
    },
}) => {

    const { obraById } = useSelector(
        (state: RootState) => state.obras
    );
    const [obraLoad, setObraLoad] = useState(false);
    const dispatch = useDispatch();

    // Cuando la vista está a punto de entrar
    useIonViewWillEnter(() => {
        // Inicializa informeLoad como false al entrar en la vista
        setObraLoad(false);
        // Hace el dispatch para obtener el nuevo informe con el nuevo informeId
        dispatch(getObraById(obraId));
    });

    // Cuando la vista está a punto de salir
    useIonViewWillLeave(() => {
        // Puedes limpiar cualquier estado aquí si es necesario
    });

    // Cuando el nuevo informe se carga completamente, cambiar informeLoad a true
    useEffect(() => {
        if (typeof obraById === 'object' && obraById !== null && '_id' in obraById && obraById._id === obraId) {
            // Verifica si obra cargada en Redux coincide con la obraIdId actual
            setObraLoad(true);
        }
    }, [obraById, obraId]);

    return (
        <IonPage>
            <IonHeader className="ion-no-border border-header">
                <ToolbarBack title="Obra" backLink="/copropietario/obras" />
            </IonHeader>
            <IonContent fullscreen className="ion-content-grey">
                {!obraLoad ? (
                    <IonGrid >
                        <IonRow className="dashboard-row">
                            <IonCol size="12" className="center-content">
                                <CustomSpinner color="primary" />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                ) : (
                    <>
                        <IonGrid >
                            <IonRow className="dashboard-row">
                                <IonCol size-md="12" size-lg="12" size-xs="12" size-sm="12">
                                    <ObraDescripcionCard
                                        obra={obraById}
                                    />
                                </IonCol>
                            </IonRow>
                        {/* </IonGrid>
                        <IonGrid className="pb-50"> */}
                            <IonRow className="dashboard-row">
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <ObraItem
                                        link={`/copropietario/obras/seguimiento/${obraId}`}
                                        title="Seguimiento"
                                        subTitle="Detalles de visitas a obra"
                                        iconName={clipboardOutline}
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <ObraItem
                                        link={`/copropietario/obras/notas/${obraId}`}
                                        title="Notas"
                                        subTitle="Anotaciones y recordatorio"
                                        iconName={list}
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <ObraItem
                                        link={`/copropietario/obras/empresa/${obraId}`}
                                        title="Empresa Constructora"
                                        subTitle="Datos de la empresa"
                                        iconName={briefcaseOutline}
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <ObraItem
                                        link={`/copropietario/obras/presupuestos/${obraId}`}
                                        title="Presupuestos"
                                        subTitle="Presupuestos presentados"
                                        iconName={cashOutline}
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <ObraItem
                                        link={`/copropietario/obras/memoria/${obraId}`}
                                        title="Memoria"
                                        subTitle="Detalles técnicos de obra"
                                        iconName={documentTextOutline}
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <ObraItem
                                        link={`/copropietario/obras/informes/${obraId}`}
                                        title="Informes"
                                        subTitle="Informes asociados"
                                        iconName={readerOutline}
                                    />
                                </IonCol>
                                <IonCol size-md="6" size-lg="4" size-xl="3" size-xs="12" size-sm="6">
                                    <ObraItem
                                        link={`/copropietario/obras/recaudos/${obraId}`}
                                        title="Recaudos Gráficos"
                                        subTitle="Planos, planillas y detalles"
                                        iconName={imagesOutline}
                                    />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </>
                )}
            </IonContent>
        </IonPage>
    );
};

export default ObraCopDetail;