import { action } from "typesafe-actions";
import { InformeTypes, IInforme } from "./types";

export const getInformesByTecnico = () =>
  action(InformeTypes.GET_INFORMES_BY_TECNICO);

export const setInformesByTecnico = (informes: [IInforme]) =>
  action(InformeTypes.SET_INFORMES_BY_TECNICO, { informes });

export const getInformesByEdificio = (id: string) =>
  action(InformeTypes.GET_INFORMES_BY_EDIFICIO, { id });

export const setInformesByEdificio = (informes: [IInforme]) =>
  action(InformeTypes.SET_INFORMES_BY_EDIFICIO, { informes });

export const getInformeById = (id: string) =>
  action(InformeTypes.GET_INFORME_BY_ID, { id });

export const setInformeById = (informe: IInforme) =>
  action(InformeTypes.SET_INFORME_BY_ID, { informe });

const actions = {
  getInformesByTecnico: getInformesByTecnico,
  setInformesByTecnico: setInformesByTecnico,
  getInformesByEdificio: getInformesByEdificio,
  setInformesByEdificio: setInformesByEdificio,
  getInformeById: getInformeById,
  setInformeById: setInformeById
};

export { actions as informeActions };
