import {
    IonCard,
    IonCardContent,
    IonIcon
} from "@ionic/react";

import "../../General.css";
import "./ObservacionDetallesCard.css";
import React from "react";
import moment from "moment";
import "moment/locale/es";
import { FiCalendar } from "react-icons/fi";
import { IObra } from "../../../store/ducks/obras/types";
import { IVisita } from "../../../store/ducks/visitas/types";
import { IObservacion } from "../../../store/ducks/observaciones/types";
import { documentAttachOutline, layersOutline } from "ionicons/icons";
import GaleriaFotos from "../../GaleriaFotos/GaleriaFotos";

export type ObservacionProps = {
    observacion?: IObservacion
};

const ObservacionDetallesCard: React.FC<ObservacionProps> = ({
    observacion
}) => {
    return (
        <IonCard className="ion-card-descripcion">
            {/* Encabezado con el título del rubro */}
            <div className="encabezado-rubro d-flex align-items-center">
                <IonIcon icon={layersOutline} className="icon-card-title"></IonIcon>
                <h4 className="titulo-rubro font-16 font-dark-grey font-weight-500">{observacion?.rubro || "Sin rubro"}</h4>

            </div>
            <IonCardContent className="card-content-box">
                <div className="mt-3 mb-3">
                    {observacion?.observacion && (
                        <div className="descripcion-txt">
                            <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Observación</h4>
                            <p className="font-14">{observacion?.observacion}</p>
                        </div>
                    )}
                    {observacion?.observacion && (
                        <div className="mt-2">
                            <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Sector</h4>
                            <p className="font-14 text-capitalize ">{observacion?.sector}</p>
                        </div>
                    )}
                    {observacion?.observacion && (
                        <div className="mt-2">
                            <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Estado</h4>
                            <p className="font-14 text-capitalize ">{observacion?.estado}</p>
                        </div>
                    )}

                    {(observacion?.imagenesGaleria && observacion.imagenesGaleria?.length>0) && (
                        <div className="mt-2">
                            <h4 className="font-16 font-dark-grey mb-1 font-weight-500">Galería de Fotos</h4>
                            <GaleriaFotos imagenes={observacion.imagenesGaleria} />
                        </div>
                    )}

                </div>

            </IonCardContent>
        </IonCard>
    );
}

export default ObservacionDetallesCard;